import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Industries() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            About Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Industries
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* Blog Start */}
      <div className="container-fluid blog py-5 mb-5">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="text-primary">Our Services</h5>
            <h1>Our Freight & Logistics Services</h1>
          </div>
          <div className="row g-5 justify-content-center">
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/sea.jpg"
                  className="img-fluid w-100 rounded-top"
                  alt=""
                  style={{ height: "280px" }}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Sea" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Sea Freight</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/air.jpg"
                  className="img-fluid w-100 rounded-top"
                  alt=""
                  style={{ height: "280px" }}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Air" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Air Freight</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/rail.jpg"
                  className="img-fluid w-100 rounded-top"
                  alt=""
                  style={{ height: "280px" }}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Rail" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Rail Freight</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/road.png"
                  className="img-fluid w-100 rounded-top"
                  alt=""
                  style={{ height: "280px" }}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Ground" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Ground Transportation</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/warehousing.png"
                  className="img-fluid w-100 rounded-top"
                  alt=""
                  style={{ height: "280px" }}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Warehousing" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Warehousing</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/door.jpg"
                  className="img-fluid w-100 rounded-top"
                  alt=""
                  style={{ height: "280px" }}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Door" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Door to Door</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/project.png"
                  className="img-fluid w-100 rounded-top"
                  alt=""
                  style={{ height: "280px" }}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Project" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Project Cargo Logistics</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/customs.png"
                  className="img-fluid w-100 rounded-top"
                  alt=""
                  style={{ height: "280px" }}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Customs" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Customs Clearance & Documentation</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/supply.jpg"
                  className="img-fluid w-100 rounded-top"
                  alt=""
                  style={{ height: "280px" }}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Supply" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Supply Chain Management</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Blog End */}

      <Footer />
    </>
  );
}
