import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Technology() {
  return (
    <>
      <Header />

      <section class="inner_page_head">
        <div class="container_fuild">
          <div class="row">
            <div class="col-md-12">
              <div class="full">
                <h3>Technology</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container heading_center">
            <h2>Technology</h2>
          </div>
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="images/technology.webp"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{ marginBottom: "25%" }}
              />
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <p> 
             
In the highly competitive global tech market, we understand that flexible, safe, reliable, and cost-effective logistics solutions are paramount to success. At {companyname}, our dedicated Technology Logistics team is here to address your specific needs and provide comprehensive support for the worldwide transportation of high-tech and consumer electronics. <br/><br/>

Our expertise extends to a wide range of tech products, including computers, laptops, semiconductors, components, smartphones, printers, TVs, gaming consoles, and accessories. Additionally, we have the capability to handle the shipping of large household appliances, also known as white goods, such as air conditioning systems, refrigerators, freezers, washing machines, dishwashers, and more.<br/><br/>

With a global network at our disposal, our experienced team members serve as your first point of contact for worldwide tech logistics. We specialize in the transportation of sensitive, fragile, and high-value goods. Our commitment to innovation and efficiency drives us to utilize cutting-edge technology and monitoring systems, ensuring real-time visibility and transparency throughout the entire logistics process. This allows you to have complete visibility into the location and status of your shipments, promoting peace of mind and enabling proactive decision-making.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
