import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Consumer() {
  return (
    <>
      <Header />

      <section class="inner_page_head">
        <div class="container_fuild">
          <div class="row">
            <div class="col-md-12">
              <div class="full">
                <h3>Consumer Goods</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container heading_center">
            <h2>Consumer Goods</h2>
          </div>
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="images/consumer.jpeg"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{ marginBottom: "25%" }}
              />
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <p>
              The success of consumer goods manufacturers increasingly relies on having a reliable supply chain in place. At {companyname} Consumer Goods Logistics, we specialize in providing solutions that make your supply chain faster, more reliable, and transparent. We offer end-to-end logistics solutions specifically tailored to the consumer goods and FMCG (Fast-Moving Consumer Goods) industry.

              <br/> Our comprehensive range of services includes customized concepts for warehousing, ensuring efficient storage and distribution of your products. We optimize your supply chain by combining our four modes of transport - air, sea, road, and rail - to create the most effective and cost-efficient transportation solution for your goods. Through the utilization of state-of-the-art IT systems, we provide maximum transparency throughout the entire supply chain, allowing you to track and monitor your shipments in real-time.

              <br/> Within our global network, our experienced team members serve as your primary point of contact for worldwide transportation of various consumer goods. This includes products such as toys, dry foods (confectionery, snacks), pet and animal supplies, ingredients and flavors, small household appliances (e.g., hair dryers), cleaning products (toiletries, home laundry), and cosmetic and personal care products.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
