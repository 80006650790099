import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header /> 

      <section class="inner_page_head">
        <div class="container_fuild">
          <div class="row">
            <div class="col-md-12">
              <div class="full">
                <h3>Air Freight</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container heading_center">
            <h2>Air Freight</h2>
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".3s"
          >
            <img
              src="images/air.png"
              className="img-fluid  rounded mt-4"
              alt=""
              style={{
                marginBottom: "1%",
                width: "100%",
                padding: "0px 120px",
              }}
            />
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".5s"
          >
            <p><br/>
              Our extensive airfreight network is designed with careful
              consideration, incorporating a well-balanced setup of gateways
              strategically positioned in key markets, complemented by efficient
              local operations. This comprehensive approach enables us to
              deliver exceptional airfreight services on a global scale. <br /> <br/>
              We take pride in providing prompt and knowledgeable customer
              service, and to ensure that we meet and exceed your expectations,
              our offices are conveniently located on-site. This proximity
              allows us to offer swift response times and personalized
              assistance to address any inquiries or concerns you may have.
              <br /><br/>
              Recognizing the unique requirements of various industries, we have
              leveraged our wealth of experience to develop specialized
              solutions tailored to meet the specific needs of Healthcare,
              Automotive, Perishables, and Fashion sectors. These
              industry-specific solutions are meticulously crafted to provide
              optimized handling, secure transportation, and timely delivery,
              ensuring the integrity and safety of your valuable goods.
              <br /><br/>
              By aligning our airfreight operations with global standards and
              industry best practices, we aim to be your preferred choice when
              it comes to airfreight logistics. Rest assured, our commitment to
              excellence and dedication to providing top-notch services remain
              at the forefront of our operations, ensuring a seamless and
              reliable experience for our valued customers worldwide.
              <br />
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
