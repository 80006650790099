import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  return (
    <>
      {/* header section strats */}
      <header className="header_section">
        <div className="container">
          <nav className="navbar navbar-expand-lg custom_nav-container ">
            <Link className="navbar-brand" to="/Home">
              <img width={160} src="images/logo.png" alt="#" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className> </span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="/Home">
                    Home <span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li className="nav-item active">
                  <Link className="nav-link" to="/About">
                    Who We Are <span className="sr-only"></span>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    {" "}
                    <span className="nav-label">
                      What We Do <span className="caret" />
                    </span>
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/Air">Air Freight</Link>
                    </li>
                    <li>
                      <Link to="/Sea">Sea Freight</Link>
                    </li>
                    <li>
                      <Link to="/Road">Road Freight</Link>
                    </li>
                    <li>
                      <Link to="/Rail">Rail Freight</Link>
                    </li>
                    <li>
                      <Link to="/Contract">Contarct Logistics</Link>
                    </li>
                    <li>
                      <Link to="/Customs">Customs Brokerage</Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    {" "}
                    <span className="nav-label">
                      Industries <span className="caret" />
                    </span>
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/Fashion">Fashion</Link>
                    </li>
                    <li>
                      <Link to="/Consumer">Consumer Goods</Link>
                    </li>
                    <li>
                      <Link to="/Healthcare">Healthcare</Link>
                    </li>
                    <li>
                      <Link to="/Industrial">Industrial</Link>
                    </li>
                    <li>
                      <Link to="/Perishables">Perishables</Link>
                    </li>
                    <li>
                      <Link to="/Technology">Technology</Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Careers">
                    Careers
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Contact">
                    Contact
                  </Link>
                 </li> <li className="nav-item">
                  <div class="product_section">
                    <div className="btn-box" style={{marginTop:'0', marginLeft:'6px'}}>
                      <Link to="/getquote">Request Quote</Link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      
      {/* end header section */}

      <Outlet />
    </>
  );
};

export default Header;
