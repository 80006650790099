import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Industrial() {
  return (
    <>
      <Header />

      <section class="inner_page_head">
        <div class="container_fuild">
          <div class="row">
            <div class="col-md-12">
              <div class="full">
                <h3>Industrial</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container heading_center">
            <h2>Industrial</h2>
          </div>
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="images/industrial.jpg"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{ marginBottom: "25%" }}
              />
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <p> 
              Industrial manufacturers understand the vital importance of a reliable and cost-efficient supply chain in achieving economic success. At {companyname}, our Industrial Logistics experts possess deep industry knowledge and extensive product expertise, enabling us to develop innovative end-to-end solutions for your supply chain processes.<br/><br/>

From managing inbound logistics to handling reverse logistics, we offer customized concepts for warehousing, contract logistics, and optimal transportation solutions that leverage the strengths of our four modes of transport (air, sea, road, and rail). Our comprehensive approach ensures that we support you throughout the entire life-cycle of your products, providing seamless and efficient logistics solutions.<br/><br/>

Operating within our global network, our experienced team members serve as your dedicated first and single point of contact for the worldwide transportation of industrial goods. Regardless of the size or complexity of your shipments, we have the expertise and resources to handle them with precision and care.</p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
