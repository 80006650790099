import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Perishables() {
  return (
    <>
      <Header />

      <section class="inner_page_head">
        <div class="container_fuild">
          <div class="row">
            <div class="col-md-12">
              <div class="full">
                <h3>Perishables</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container heading_center">
            <h2>Perishables</h2>
          </div>
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="images/perishables.jpg"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{ marginBottom: "25%" }}
              />
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <p> 
              When it comes to transporting perishable goods, whether they be tropical fruits, seafood, flowers, or berries, we are here to provide comprehensive support throughout the entire supply chain. Our unwavering goal is to deliver first-class service that ensures the integrity and freshness of your perishable products.<br/><br/>

We understand the unique requirements and challenges of transporting perishable goods, which is why we continuously invest in the latest equipment and expand our global network. Our dedicated employees around the world are highly trained to handle perishable goods professionally and with utmost care. This enables us to offer you complete management of the refrigerated supply chain, ensuring reliability and efficiency from start to finish.<br/><br/>

At {companyname}, we prioritize the quality and safety of your temperature-controlled goods. Our state-of-the-art facilities and systems are HACCP-certified, and we have experienced professionals who meticulously oversee the shipping and continuous monitoring of your perishable items. From door to door, we ensure that your goods are handled in accordance with the highest standards, maintaining the optimal temperature and conditions throughout the entire journey.</p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
