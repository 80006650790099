import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Healthcare() {
  return (
    <>
      <Header />

      <section class="inner_page_head">
        <div class="container_fuild">
          <div class="row">
            <div class="col-md-12">
              <div class="full">
                <h3>Healthcare</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container heading_center">
            <h2>Healthcare</h2>
          </div>
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="images/healthcare.jpg"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{ marginBottom: "25%" }}
              />
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <p> 
              The healthcare industry requires specialized and highly regulated logistics processes to safeguard the quality, efficacy, and integrity of its products. At {companyname}, we understand these unique demands and are dedicated to providing global logistics solutions that meet the stringent requirements of the healthcare sector.<br/><br/>

We maintain control over our scalable operational processes by adhering to regulatory-compliant quality standards. This commitment allows us to offer reliable supply chain solutions that span across the globe. We work closely with our customers to develop tailor-made concepts that ensure the secure and temperature-controlled transportation, storage, packaging, and additional value-added services for their healthcare products.<br/><br/>

Our expertise extends to various segments within the healthcare industry, including pharmaceuticals, medical devices, diagnostics, consumer health, and other healthcare-related products. We understand the critical nature of cold chain logistics, particularly for high-value pharmaceuticals and vaccines. Through our cold chain solutions, we ensure the strict temperature control and monitoring required to maintain the efficacy of these sensitive products. </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
