import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Road() {
  return (
    <>
      <Header />

      <section class="inner_page_head">
        <div class="container_fuild">
          <div class="row">
            <div class="col-md-12">
              <div class="full">
                <h3>Road Freight</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container heading_center">
            <h2>Road Freight</h2>
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".3s"
          >
            <img
              src="images/road.jpg"
              className="img-fluid  rounded mt-4"
              alt=""
              style={{
                marginBottom: "1%",
                width: "100%",
                padding: "0px 120px",
              }}
            />
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".5s"
          >
            <p><br/>
            We understand that transportation requirements can vary, and we are committed to providing tailored solutions to meet your specific needs, whether it's direct transport (FTL/FCL) or groupage (LTL/LCL). Through the utilization of innovative technologies and the expertise of our exceptional personnel, we are able to offer transport solutions that are precisely aligned with your requirements.

            <br/><br/>By choosing our services, you can take advantage of several key benefits. First and foremost, we prioritize fast transit times, ensuring that your shipments reach their destination promptly. We also provide reliable capacities, ensuring that we can accommodate your transportation needs without any delays or capacity constraints. Additionally, our wide range of products allows us to cater to diverse cargo types and sizes, ensuring flexibility in meeting your specific transportation demands. 

            <br/><br/>Moreover, our commitment to service excellence is unrivaled. We take pride in delivering unparalleled service quality, ensuring that your goods are handled with the utmost care and attention throughout the entire transportation process. Our fleet of trucks operates diligently every day, ensuring efficient and cost-effective delivery of your goods.

            <br/><br/>With our comprehensive transportation solutions, cutting-edge technology, and dedicated personnel, we are well-equipped to provide you with seamless and reliable transportation services tailored to your requirements.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
