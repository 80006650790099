import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <div className="hero_area">
        <Header />
        {/* slider section */}
        <section
          className="slider_section w3-banner jarallax"
          style={{ background: "#00000082" }}
        >
          <video className="myvideo" autoPlay muted loop style={{ zIndex: -1 }}>
            <source src="images/video.mp4" type="video/mp4" />
          </video>
          <div style={{ width: "100%", height: "" }}>
            <div
              id="customCarousel1"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="container ">
                    <div className="row">
                      <div className="col-md-7 col-lg-12 ">
                        <div className="detail-box text-center">
                          <h1 style={{ color: "white" }}>
                            <span>We Are</span>
                            <br />
                            {companyname}
                          </h1>
                          <h4 style={{ color: "#d3d3d3" }}>
                            We believe in providing personalized and tailored
                            freight forwarding solutions that align with the
                            specific needs of each customer. By taking the time
                            to understand their unique requirements, we can
                            develop strategies and logistics plans that optimize
                            efficiency, reduce costs, and enhance overall supply
                            chain performance.
                          </h4>
                          <div className="btn-box">
                            <Link to="/About" className="btn1">
                              Learn More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item ">
                <div className="container ">
                    <div className="row">
                      <div className="col-md-7 col-lg-12 ">
                        <div className="detail-box text-center">
                          <h1 style={{ color: "white" }}>
                            <span>Making Global</span>
                            <br />
                            Trade Simple
                          </h1>
                          <h4 style={{ color: "#d3d3d3" }}>
                          At our core, we are dedicated to simplifying the complexities of global trade for our customers. We understand the challenges they face, from navigating customs regulations to coordinating logistics across borders. 
                          </h4>
                          <div className="btn-box">
                            <Link to="/Services" className="btn1">
                              Explore Services
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                <div className="container ">
                    <div className="row">
                      <div className="col-md-7 col-lg-12 ">
                        <div className="detail-box text-center">
                          <h1 style={{ color: "white" }}>
                            <span>We Are</span>
                            <br />
                            {companyname}
                          </h1>
                          <h4 style={{ color: "#d3d3d3" }}>
                            We believe in providing personalized and tailored
                            freight forwarding solutions that align with the
                            specific needs of each customer. By taking the time
                            to understand their unique requirements, we can
                            develop strategies and logistics plans that optimize
                            efficiency, reduce costs, and enhance overall supply
                            chain performance.
                          </h4>
                          <div className="btn-box">
                            <Link href className="btn1">
                              Shop Now
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <ol className="carousel-indicators">
                  <li
                    data-target="#customCarousel1"
                    data-slide-to={0}
                    className="active"
                  />
                  <li data-target="#customCarousel1" data-slide-to={1} />
                  <li data-target="#customCarousel1" data-slide-to={2} />
                </ol>
              </div>
            </div>
          </div>
        </section>
        {/* end slider section */}
      </div>
      {/* why section */}
      <section className="why_section layout_padding">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>We Are {companyname}</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="box ">
                <div className="img-box">
                  <i class="fa fa-eye fa-5x" aria-hidden="true"></i>
                </div>
                <div className="detail-box">
                  <h5>Vision</h5>
                  <p>
                    {" "}
                    We embrace sustainable economic development, foster
                    creativity, and drive innovation in the realm of worldwide
                    logistics. It is our shared responsibility to create this
                    future, united in purpose.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box ">
                <div className="img-box">
                  <i class="fa fa-bullseye fa-5x" aria-hidden="true"></i>
                </div>
                <div className="detail-box">
                  <h5>Mission</h5>
                  <p>
                    {" "}
                    Our mission is to connect the world every day, while
                    upholding sustainable practices. We understand that our
                    actions impact both current and future generations, and we
                    strive to make positive contributions to society and the
                    environment.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end why section */}
      {/* arrival section */}
      <section className="arrival_section">
        <div className="container">
          <div className="box">
            <div className="arrival_bg_box">
              <img
                src="images/arrival-bg.jpg"
                alt=""
                style={{ padding: "50px 10px" }}
              />
            </div>
            <div className="row">
              <div className="col-md-6 ml-auto pt-4">
                <div class="heading_container ">
                  <h2>Who We Are</h2>
                </div>
                <p style={{ marginTop: "20px", marginBottom: "30px" }}>
                  At {companyname}, we pride ourselves on our ability to deliver
                  reliable and high-quality logistics services. With our
                  extensive experience, global network, and dedication to
                  customer satisfaction, we continue to adapt to the
                  ever-changing demands of the industry. We remain committed to
                  providing our customers with the right solutions, supported by
                  cutting-edge technology and a focus on transparency and
                  efficiency in supply chain management. <br />
                  <br /> we have continuously expanded our capabilities and
                  expertise to meet the evolving needs of our customers. Today,
                  we offer a comprehensive range of high-performance logistics
                  products, including Airfreight, Seafreight, Road & Rail, and
                  Contract Logistics.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end arrival section */}
      {/* product section */}
      <section className="product_section layout_padding">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>
              Our <span>Industries</span>
            </h2>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="box" style={{ padding: "0px" }}>
                <div className="option_container">
                  <div className="options">
                    <Link to="/Fashion" className="option2">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="img-box">
                  <img
                    src="images/fashion.webp"
                    style={{ maxHeight: "200px" }}
                    alt=""
                  />
                </div>
                <div className=" ">
                  <h3 className="text-center"> Fashion</h3>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="box" style={{ padding: "0px" }}>
                <div className="option_container">
                  <div className="options">
                    <Link to="/Consumer" className="option2">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="img-box">
                  <img
                    src="images/consumer.jpeg"
                    style={{ maxHeight: "200px" }}
                    alt=""
                  />
                </div>
                <div className=" ">
                  <h3 className="text-center"> Consumer Goods</h3>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="box" style={{ padding: "0px" }}>
                <div className="option_container">
                  <div className="options">
                    <Link to="/Healthcare" className="option2">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="img-box">
                  <img
                    src="images/healthcare.jpg"
                    style={{ maxHeight: "200px" }}
                    alt=""
                  />
                </div>
                <div className=" ">
                  <h3 className="text-center"> Healthcare</h3>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="box" style={{ padding: "0px" }}>
                <div className="option_container">
                  <div className="options">
                    <Link to="/Industrial" className="option2">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="img-box">
                  <img
                    src="images/industrial.jpg"
                    style={{ maxHeight: "200px" }}
                    alt=""
                  />
                </div>
                <div className=" ">
                  <h3 className="text-center"> Industrial</h3>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="box" style={{ padding: "0px" }}>
                <div className="option_container">
                  <div className="options">
                    <Link to="/Perishables" className="option2">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="img-box">
                  <img
                    src="images/perishables.jpg"
                    style={{ maxHeight: "200px" }}
                    alt=""
                  />
                </div>
                <div className=" ">
                  <h3 className="text-center"> Perishables</h3>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="box" style={{ padding: "0px" }}>
                <div className="option_container">
                  <div className="options">
                    <Link to="/Technology" className="option2">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="img-box">
                  <img
                    src="images/technology.webp"
                    style={{ maxHeight: "200px" }}
                    alt=""
                  />
                </div>
                <div className=" ">
                  <h3 className="text-center"> Technology</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end product section */}
      {/* subscribe section */}

      {/* end subscribe section */}
      <div className="container-fluid blog py-5 mb-5">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <div className="heading_container heading_center">
              <h2>
                Our <span>Services</span>
              </h2>
            </div>
          </div>
          <div className="row g-5 justify-content-center">
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="images/air.png"
                  className="img-fluid w-100 rounded-top"
                  alt=""
                  style={{ height: "280px", border: "solid 2px #2fa9b8" }}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link
                      to="/Air"
                      className="btn text-white"
                      style={{ background: "#2fa9b8" }}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px", marginBottom: "30px" }}
                >
                  <h5 className>Air Freight</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="images/sea.jpg"
                  className="img-fluid w-100 rounded-top"
                  alt=""
                  style={{ height: "280px", border: "solid 2px #2fa9b8" }}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link
                      to="/Sea"
                      className="btn text-white"
                      style={{ background: "#2fa9b8" }}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px", marginBottom: "30px" }}
                >
                  <h5 className>Sea Freight</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="images/road.jpg"
                  className="img-fluid w-100 rounded-top"
                  alt=""
                  style={{ height: "280px", border: "solid 2px #2fa9b8" }}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link
                      to="/Road"
                      className="btn text-white"
                      style={{ background: "#2fa9b8" }}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px", marginBottom: "30px" }}
                >
                  <h5 className>Road Freight</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="images/rail.jpeg"
                  className="img-fluid w-100 rounded-top"
                  alt=""
                  style={{ height: "280px", border: "solid 2px #2fa9b8" }}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link
                      to="/Rail"
                      className="btn text-white"
                      style={{ background: "#2fa9b8" }}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px", marginBottom: "30px" }}
                >
                  <h5 className>Rail Freight</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="images/contract.jpg"
                  className="img-fluid w-100 rounded-top"
                  alt=""
                  style={{ height: "280px", border: "solid 2px #2fa9b8" }}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link
                      to="/Contract"
                      className="btn text-white"
                      style={{ background: "#2fa9b8" }}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px", marginBottom: "30px" }}
                >
                  <h5 className>Contract Logistics</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="images/customs.jpg"
                  className="img-fluid w-100 rounded-top"
                  alt=""
                  style={{ height: "280px", border: "solid 2px #2fa9b8" }}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link
                      to="/Customs"
                      className="btn text-white"
                      style={{ background: "#2fa9b8" }}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px", marginBottom: "30px" }}
                >
                  <h5 className>Customs Brokerage</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* end client section */}

      <Footer />
    </>
  );
}
