import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Rail() {
  return (
    <>
      <Header />

      <section class="inner_page_head">
        <div class="container_fuild">
          <div class="row">
            <div class="col-md-12">
              <div class="full">
                <h3>Rail Freight</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container heading_center">
            <h2>Rail Freight</h2>
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".3s"
          >
            <img
              src="images/rail.jpeg"
              className="img-fluid  rounded mt-4"
              alt=""
              style={{
                marginBottom: "1%",
                width: "100%",
                padding: "0px 120px",
              }}
            />
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".5s"
          >
            <p><br/>
            Our specialized rail services offer a seamless transfer of your shipments to rail, providing numerous advantages in terms of efficiency, economy, and environmental impact. By utilizing rail transportation, we achieve unparalleled efficiency in terms of cost-effectiveness and reduced CO2 emissions.

            <br/><br/>In addition to our rail services that operate between dispatch and receiving stations, we also take care of all pre- and post-carriage logistics as part of our combined transport solutions. This means that we handle the transportation of your goods from the point of origin to the rail terminal, as well as from the rail terminal to the final destination, ensuring a comprehensive end-to-end service.

            <br/><br/>By leveraging rail transport, we can significantly reduce the ecological footprint associated with traditional road transportation. Rail transport is known for its environmental advantages, as it generates lower CO2 emissions compared to other modes of transportation. Our commitment to sustainability is reflected in our choice of rail services, enabling us to contribute to the global effort of reducing carbon emissions and promoting greener logistics solutions.

            <br/><br/>Furthermore, our rail services offer exceptional efficiency in terms of cost savings. Rail transportation is often more cost-effective than road transport for long-distance haulage, allowing us to pass on these savings to our customers. This economic advantage, combined with the environmental benefits, makes our specialized rail services an attractive and sustainable choice for your transportation needs.

            <br/><br/>With our expertise in rail logistics and our commitment to providing efficient, economical, and environmentally friendly solutions, we ensure that your shipments are handled with utmost care and delivered to their destinations seamlessly, while also minimizing the impact on the environment.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
