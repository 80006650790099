import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Fashion() {
  return (
    <>
      <Header />

      <section class="inner_page_head">
        <div class="container_fuild">
          <div class="row">
            <div class="col-md-12">
              <div class="full">
                <h3>Fashion</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container heading_center">
            <h2>Fashion</h2>
          </div>
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="images/fashion.webp"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{ marginBottom: "25%" }}
              />
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <p> 
              In the dynamic world of the fashion industry, a flexible supply chain has emerged as a critical factor for success. With globalization, digitalization, rapidly changing trends, and unexpected events like the Covid-19 pandemic, retailers are adapting by implementing flexible omnichannel concepts and placing a greater emphasis on e-commerce.

              <br/> <br/>At {companyname} Fashion Logistics, we recognize the importance of a tailored and integrated supply chain strategy as a competitive advantage for fashion businesses. We specialize in making your supply chain faster, leaner, and more flexible than ever before. Our goal is to provide customized solutions for replenishment, warehousing, and transport that significantly accelerate your reaction times to meet the demands of a fast-paced industry.

              <br/> <br/>Our services are available worldwide, covering the entire journey from production facilities to the point of sale. We offer value-added services such as quality control, reprocessing, and pick & pack utilizing RFID technology. These services allow you to focus on your core competencies while leaving the intricacies of supply chain management to our experienced team.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
