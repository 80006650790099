import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  return (
    <>
       {/* footer start */}
       <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="full">
                  <div className="logo_footer">
                    <Link to="/Home"><img width={150} src="images/logo.png" alt="#" /></Link >
                  </div>
                 <div className="information_f">
                 At {companyname}, we pride ourselves on our ability to deliver
                  reliable and high-quality logistics services. With our
                  extensive experience, global network, and dedication to
                  customer satisfaction, we continue to adapt to the
                  ever-changing demands of the industry. 
                 </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-7">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="widget_menu">
                          <h3>Menu</h3>
                          <ul>
                            <li><Link to="/Home">Home</Link ></li>
                            <li><Link to="/About">About Us</Link ></li>
                            <li><Link to="/services">Services</Link ></li>
                            <li><Link to="#">Industries</Link ></li>
                            <li><Link to="/Contact">Contact</Link ></li>
                            <li><Link to="/Careers">Careers</Link ></li>
                            <li><Link to="/Getquote">Get Quote</Link ></li>

                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="widget_menu">
                          <h3>Our Services</h3>
                          <ul>
                          <li>
                      <Link to="/Air">Air Freight</Link>
                    </li>
                    <li>
                      <Link to="/Sea">Sea Freight</Link>
                    </li>
                    <li>
                      <Link to="/Road">Road Freight</Link>
                    </li>
                    <li>
                      <Link to="/Rail">Rail Freight</Link>
                    </li>
                    <li>
                      <Link to="/Contract">Contarct Logistics</Link>
                    </li>
                    <li>
                      <Link to="/Customs">Customs Brokerage</Link>
                    </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>     
                  <div className="col-md-5">
                    <div className="widget_menu">
                      <h3>Contact Us</h3>
                      <div className="information_f">
                    <p><strong>ADDRESS:</strong>{companyaddress}</p>
                    <p><strong>TELEPHONE:</strong> {companynumber}</p>
                    <p><strong>EMAIL:</strong> {companyemail}</p>
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* footer end */}
        <div className="cpy_">
          <p>© 2023 All Rights Reserved By <Link to="/Home">{companyname}</Link ></p>
        </div>    
      <Outlet />
    </>
  );
};

export default Footer;
