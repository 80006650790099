import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />

      <section class="inner_page_head">
        <div class="container_fuild">
          <div class="row">
            <div class="col-md-12">
              <div class="full">
                <h3>Sea Freight</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container heading_center">
            <h2>Sea Freight</h2>
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".3s"
          >
            <img
              src="images/sea.jpg"
              className="img-fluid  rounded mt-4"
              alt=""
              style={{
                marginBottom: "1%",
                width: "100%",
                padding: "0px 120px",
              }}
            />
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".5s"
          >
            <p><br/>
            At the core of our approach, we view business relationships as true partnerships. We believe that fostering a close and trustful cooperation is the key to comprehending your unique needs and collaboratively developing optimal sea freight solutions. Our ultimate goal is to contribute to the success of your business by prioritizing sustainable concepts.

            <br/><br/>With our extensive expertise and a well-established global network, we have cultivated strong relationships with the world's leading shipping companies. Paired with our ongoing efforts in advancing our digital tools, we ensure the seamless transportation of your shipments across all the oceans of the world, providing you with a comprehensive solution from a single source.

            <br/><br/>Whether you require full-container-loads (FCL), less-than-container-loads (LCL), or complex project shipments, we are well-equipped to meet your specific requirements and deliver your goods to the intended destination. Our services encompass not only transportation but also extend to crucial aspects such as customs clearance, meticulous shipment documentation, and the careful handling of your valuable cargo. These offerings are just a glimpse of our comprehensive product portfolio aimed at providing you with a holistic and efficient sea freight experience.
              <br />
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
