import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Contact from "./pages/Contact";
import Sea from "./pages/Sea";
import Rail from "./pages/Rail";
import Air from "./pages/Air";
import Road from "./pages/Road";
import Contract from "./pages/Contract";
import Industrial from "./pages/Industrial";
import Fashion from "./pages/Fashion";
import Customs from "./pages/Customs";

import Consumer from "./pages/Consumer";
import Healthcare from "./pages/Healthcare";
import Perishables from "./pages/Perishables";
import Technology from "./pages/Technology";
import Careers from "./pages/Careers";
import Getquote from "./pages/Getquote";
import About from "./pages/About";
import Services from "./pages/Services";
import Industries from "./pages/Industries";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";



import Signin from "./admin/Signin";


export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Sea" element={<Sea/>} />
          <Route path="/Rail" element={<Rail />} />
          <Route path="/Air" element={<Air />} />
          <Route path="/Road" element={<Road />} />
          <Route path="/Contract" element={<Contract />} />
          <Route path="/Customs" element={<Customs />} />
          <Route path="/Getquote" element={<Getquote />} />
          <Route path="/Consumer" element={<Consumer />} />
          <Route path="/Healthcare" element={<Healthcare />} />
          <Route path="/Perishables" element={<Perishables />} />
          <Route path="/Technology" element={<Technology />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Industrial" element={<Industrial />} />
          <Route path="/Fashion" element={<Fashion />} />
          <Route path="/Industries" element={<Industries />} />
          <Route path="/Signin" element={<Signin />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />

        </Routes>
      </Router>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));