import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Customs() {
  return (
    <>
      <Header />

      <section class="inner_page_head">
        <div class="container_fuild">
          <div class="row">
            <div class="col-md-12">
              <div class="full">
                <h3>Customs Clearance</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container heading_center">
            <h2>Customs Clearance</h2>
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".3s"
          >
            <img
              src="images/customs.jpg"
              className="img-fluid  rounded mt-4"
              alt=""
              style={{
                marginBottom: "1%",
                width: "100%",
                padding: "0px 120px",
              }}
            />
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".5s"
          >
            <p><br/>
            In the end-to-end supply chain solutions, customs clearance plays a vital role. However, navigating the intricacies of customs regulations, duties, fees, taxes, and related procedures can be a complex task. That's why it is crucial to have a reliable partner by your side who can efficiently manage and oversee these aspects. With our expertise in customs clearance, CBP (Customs and Border Protection) regulations, import and export logistics, reconciliation, and cross-border operations, we are well-equipped to handle the complexities of the process.

            <br/><br/>We understand that each client and trade regime has unique requirements and considerations. Therefore, we adopt a focused approach, tailoring our services to meet the specific needs of our clients. Our experienced team is dedicated to providing comprehensive support and guidance, ensuring that your trade compliance procedures are appropriately established.

            <br/><br/>As an added value to our customers, we offer Customs Compliance Advisory Services. This additional support aims to assist you with all your compliance needs, ensuring that you stay up-to-date with the ever-changing customs regulations and requirements. We recognize the importance of maintaining compliance and are committed to helping you navigate through the complexities of international trade.

            <br/><br/>Furthermore, we prioritize transparency and visibility throughout the brokerage entry process. With our real-time visibility capabilities, you can have complete visibility into the status and progress of your customs clearance, providing you with peace of mind and confidence in the process.

            <br/><br/>By choosing us as your customs clearance partner, you can rely on our extensive knowledge, experience, and dedication to ensure smooth and compliant customs procedures. We are here to support you at every step, providing efficient customs clearance services while offering valuable advisory assistance to optimize your trade compliance procedures.</p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
