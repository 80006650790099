import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contract() {
  return (
    <>
      <Header />

      <section class="inner_page_head">
        <div class="container_fuild">
          <div class="row">
            <div class="col-md-12">
              <div class="full">
                <h3>Contract Logistics</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container heading_center">
            <h2>Contract Logistics</h2>
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".3s"
          >
            <img
              src="images/contract.jpg"
              className="img-fluid  rounded mt-4"
              alt=""
              style={{
                marginBottom: "1%",
                width: "100%",
                padding: "0px 120px",
              }}
            />
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".5s"
          >
            <p><br/>
            <br/><br/>We believe in the power of collaboration and strive to work closely with our customers from the initial discussions to the final implementation stage. Our goal is to develop a sustainable logistics solution strategy that is specifically tailored to meet your unique requirements.

            <br/><br/>To achieve this, we leverage our established systems, methodologies, and resources. With these tools at our disposal, we can swiftly and efficiently analyze your current business situation and project future scenarios. Throughout this process, we take into consideration your specific requests while also applying our wealth of experience and knowledge to craft the most suitable solution. At {companyname}, we understand that a one-size-fits-all approach is not effective, which is why we prioritize customization.

            <br/><br/>We understand that entrusting your business operations or starting a new relationship with a logistics service provider may come with inherent risks. However, we have developed proven project management tools and methodologies, coupled with a team of experienced professionals, to minimize these risks. We work diligently to de-risk all work streams within a project, ensuring a seamless implementation process that has no negative impact on your business operations.

            <br/><br/>At {companyname}, we value efficient communication and have established well-designed organizational structures to provide you with a simple and fast communication channel. This ensures that your business keeps moving smoothly, and any concerns or queries are addressed promptly.

            <br/><br/>We are excited to work with you and establish a mutually beneficial business relationship. Let's collaborate and create successful logistics solutions together.

</p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
