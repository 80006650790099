import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function whoweare() {
  return (
    <>
      <Header />
      <section class="inner_page_head">
        <div class="container_fuild">
          <div class="row">
            <div class="col-md-12">
              <div class="full">
                <h3>Who We Are</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="arrival_section">
        <div className="container">
          <div className="box">
            <div className="arrival_bg_box">
              <img src="images/arrival-bg.jpg" alt="" style={{padding:'50px 10px'}} />
            </div>
            <div className="row">
              <div className="col-md-6 ml-auto pt-4">
              <div class="heading_container "><h2>Who We Are</h2></div>
                <p style={{ marginTop: "20px", marginBottom: "30px" }}>
                  At {companyname}, we pride ourselves on our ability to deliver
                  reliable and high-quality logistics services. With our
                  extensive experience, global network, and dedication to
                  customer satisfaction, we continue to adapt to the
                  ever-changing demands of the industry. We remain committed to
                  providing our customers with the right solutions, supported by
                  cutting-edge technology and a focus on transparency and
                  efficiency in supply chain management. <br/>
                  <br/> we have continuously expanded our capabilities and expertise to meet the evolving needs of our customers. Today, we offer a comprehensive range of high-performance logistics products, including Airfreight, Seafreight, Road & Rail, and Contract Logistics.

                  <br/><br/>With these diverse product offerings, we are able to provide tailored solutions for the complex logistics requirements of our customers. Whether it's the speed and efficiency of airfreight, the cost-effectiveness of seafreight, the flexibility of road and rail transport, or the comprehensive management of contract logistics, we have the right solution to meet various logistical demands.

                  <br/> <br/>In line with our commitment to innovation, we embrace future-oriented digital services to enhance our operations. By leveraging advanced technologies, we ensure maximum transparency and enable more efficient supply chains. These digital solutions provide real-time tracking and monitoring, streamlined processes, and improved visibility across the entire logistics journey.
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why_section layout_padding">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>We Are {companyname}</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="box ">
                <div className="img-box">
                  <i class="fa fa-eye fa-5x" aria-hidden="true"></i>
                </div>
                <div className="detail-box">
                  <h5>Vision</h5>
                  <p>
                    {" "}
                    We embrace sustainable economic development, foster
                    creativity, and drive innovation in the realm of worldwide
                    logistics. It is our shared responsibility to create this
                    future, united in purpose.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box ">
                <div className="img-box">
                  <i class="fa fa-bullseye fa-5x" aria-hidden="true"></i>
                </div>
                <div className="detail-box">
                  <h5>Mission</h5>
                  <p>
                    {" "}
                    Our mission is to connect the world every day, while
                    upholding sustainable practices. We understand that our
                    actions impact both current and future generations, and we
                    strive to make positive contributions to society and the
                    environment.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
